// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-sections-contact-section-tsx": () => import("./../../../src/pages/landing/sections/ContactSection.tsx" /* webpackChunkName: "component---src-pages-landing-sections-contact-section-tsx" */),
  "component---src-pages-landing-sections-ecommerce-js": () => import("./../../../src/pages/landing/sections/Ecommerce.js" /* webpackChunkName: "component---src-pages-landing-sections-ecommerce-js" */),
  "component---src-pages-landing-sections-faq-section-js": () => import("./../../../src/pages/landing/sections/FAQSection.js" /* webpackChunkName: "component---src-pages-landing-sections-faq-section-js" */),
  "component---src-pages-landing-sections-fifth-section-js": () => import("./../../../src/pages/landing/sections/FifthSection.js" /* webpackChunkName: "component---src-pages-landing-sections-fifth-section-js" */),
  "component---src-pages-landing-sections-first-section-js": () => import("./../../../src/pages/landing/sections/FirstSection.js" /* webpackChunkName: "component---src-pages-landing-sections-first-section-js" */),
  "component---src-pages-landing-sections-fourth-section-js": () => import("./../../../src/pages/landing/sections/FourthSection.js" /* webpackChunkName: "component---src-pages-landing-sections-fourth-section-js" */),
  "component---src-pages-landing-sections-second-section-js": () => import("./../../../src/pages/landing/sections/SecondSection.js" /* webpackChunkName: "component---src-pages-landing-sections-second-section-js" */),
  "component---src-pages-landing-sections-seventh-section-js": () => import("./../../../src/pages/landing/sections/SeventhSection.js" /* webpackChunkName: "component---src-pages-landing-sections-seventh-section-js" */),
  "component---src-pages-landing-sections-sixth-section-js": () => import("./../../../src/pages/landing/sections/SixthSection.js" /* webpackChunkName: "component---src-pages-landing-sections-sixth-section-js" */),
  "component---src-pages-landing-sections-third-section-js": () => import("./../../../src/pages/landing/sections/ThirdSection.js" /* webpackChunkName: "component---src-pages-landing-sections-third-section-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-temp-first-section-js": () => import("./../../../src/pages/temp/FirstSection.js" /* webpackChunkName: "component---src-pages-temp-first-section-js" */),
  "component---src-pages-temp-js": () => import("./../../../src/pages/temp.js" /* webpackChunkName: "component---src-pages-temp-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */)
}

